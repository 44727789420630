import API from "@api/api"
import { Alert, Button, Modal, Space, message } from "antd"
import { ExclamationCircleFilled } from '@ant-design/icons';
import eventBus from '@utils/eventBus';
import { MODEL_PARAMS, DRESSFORM_PARAMS, COMMODITY_PARAMS, TRYON_PARAMS } from './BUILD_PARAMS_CONST'
import axios from 'axios';
import Utils from '@utils/utils'
import { IMAGE_TYPE } from "@utils/CONST"
import { CLOTH_TYPE } from "./CONST";
import PSAIModal from "@PSAIComponents/PSAIModal";
import {maskPrediction} from './workflowOfPrediction'
import { formartGenerateParams } from "./middleware";

const checkInpaintPredictionParamsExpired = (params, taskId, projectName, imageType, errorCallback) => {
    const formartParams = {...params}
    const staticParams = (
        imageType == IMAGE_TYPE.MODEL?
            MODEL_PARAMS :
            imageType == IMAGE_TYPE.CLOTHES ?
                TRYON_PARAMS :
                imageType == IMAGE_TYPE.COMMODITY ?
                    COMMODITY_PARAMS :
                    DRESSFORM_PARAMS
    )
    for (let param of staticParams) {
        if (!formartParams.hasOwnProperty(param.key)) {
            if (param.hasOwnProperty('default')) {
                formartParams[param.key] = param.default
            } else {
                console.log('can`t continue...', 1)
                PSAIModal.confirm({
                    title: '无法继续，需重选模特和场景后生成',
                    content: '版本已更新，无法复用之前的选择，请重选模特和场景',
                    okText: '去重选',
                    cancelText: '取消',
                    onOk() {
                        errorCallback ? errorCallback() : reselectModelAndBackground(taskId, imageType, projectName)
                    },
                    onCancel() {},
                })
                return Promise.resolve({ isNotExpired: false })
            }            
        } 
    }
    return Promise.resolve({ isNotExpired: true, formartParams })
}

// 算法处理函数
const inpaintPrediction = (buildParams, imageType, replica=false) => {
    let api = API.inpaintPrediction
    if (imageType == IMAGE_TYPE.MODEL) api = API.inpaintPrediction
    if (imageType == IMAGE_TYPE.CLOTHES) api = API.virtualtryonPrediction
    if (imageType == IMAGE_TYPE.DRESSFORM) api = API.aidressformPrediction
    if (imageType == IMAGE_TYPE.COMMODITY) api = API.inpaintPredictionGoods
    return api(buildParams, replica ? {num: 4} : null).then(res => {
        if (!res || res.code != 0 || !res.data) {
            if (res.code == -5012) {
                eventBus.emit('not_enough_points')
                return false
            } else throw new Error(res?.message || '算法生成失败，请联系客服')
        }

        eventBus.emit('updatePower')
        
        return {
            algoType: res.attr?.algoType,
            algoIds: res.data
        }
    }).catch(err => {
        message.error(err?.message)
    })
}

// 项目生成函数
const projectCreate = ({ id, imageType, algoType, inpaint, inputImage, mask, projectName, imageAntispamId }) => {
    return API.projectCreate({
        id: id,
        projectName: projectName,
        imageType: imageType,
        algoType,
        inputImage: inputImage,
        inpaint: inpaint,
        mask: mask,
        imageAntispamId: imageAntispamId
    }).then(res => {
        if (!res || res.code != 0 || !res.data) {
            throw new Error(res?.message || '任务生成失败，请稍后重试')
        }
        return res.data
    }).catch(err => {
        message.error(err?.message)
    })
}

// 检查任务生成状态
const checkTaskStatus = (taskList) => {
    return Promise.all(
        taskList.map(item => API.getTaskImages({ taskId: item.taskId }))
    ).then((res) => {
        let failRes = res.find(item => item.code !== 0 || !item.data)
        if (failRes) {
            throw new Error(failRes?.message)
        }
        if (res.find(item => item?.data?.inpaint?.status == 'failed')) {
            throw new Error('生成失败，请稍后重试')
        } else {
            message.info('生成中，请稍等片刻')
            return true
        }
    }).catch(err => {
        message.error(err?.message)
        return false
    })
}

const reselectModelAndBackground = (taskId, imageType, projectName) => {
    Utils.cacheTaskInfo(taskId, projectName, imageType).then(() => {
        eventBus.emit('navigateTo', `/home/homemain/${imageType}`, { state: {rebuild: true}})
    })
}

const checkBgAndModelExpired = (imageType, taskId, projectName, attr, lastInput={}, errorCallback) => {
    const { background, person, pose } = attr
    const {
        mote_bg_style,
        mote_style, // 模特参数
        bkg_style, // tryon的场景参数
        person_style, // tryon的姿势参数
    } = lastInput
    const bgApi = imageType == IMAGE_TYPE.COMMODITY ?
                    API.commodityBackgroundList : API.modelBackgroundList
    const modelApi = API.modelPersonList
    const poseApi = API.modelTryOnList
    return Promise.all([
        modelApi(),
        poseApi(),
        bgApi({ imageType: imageType })
    ]).then(([modelRes, poseRes, bgRes]) => {
        if (!modelRes || modelRes.code != 0 || !modelRes.data) throw new Error(modelRes?.message)
        if (!poseRes || poseRes.code != 0 || !poseRes.data) throw new Error(poseRes?.message)
        if (!bgRes || bgRes.code != 0 || !bgRes.data) throw new Error(bgRes?.message)
        let bgPass = true
        let modelPass = true
        let posePass = true
        if (background) bgPass = !!bgRes.data.find(item => item.name == background || item.subList?.find(subitem => subitem.name == background)) // 场景
        if (mote_bg_style) bgPass = !!bgRes.data.find(item => item.name == mote_bg_style || item.subList?.find(subitem => subitem.name == mote_bg_style)) // 场景
        if (bkg_style) bgPass = !!bgRes.data.find(item => item.name == bkg_style || item.subList?.find(subitem => subitem.name == bkg_style)) // tryon的场景
        if (person) modelPass = !!modelRes.data.find(item => item.name == person || item.subList?.find(subitem => subitem.name == person)) // 模特
        if (mote_style) modelPass = !!modelRes.data.find(item => item.name == mote_style || item.subList?.find(subitem => subitem.name == mote_style)) // 模特
        if (pose) posePass = !!poseRes.data.find(item => item.name == pose || item.subList?.find(subitem => subitem.name == pose)) // 姿势
        if (person_style) posePass = !!poseRes.data.find(item => item.name == person_style || item.subList?.find(subitem => subitem.name == person_style)) // tryon的姿势
        if (bgPass && modelPass && posePass) {
            return true
        } else {
            let content = ''
            let title = ''
            if (!bgPass && !modelPass) {
                content = '原模特和场景组已更新，无法复用之前的选择，请重选模特和场景后生成'
                title = '无法继续，需重选模特和场景后生成'
            } else if (!bgPass) {
                content = '原场景组已更新，无法复用之前的选择，请重选场景'
                title = '无法继续，需重选场景后生成'
            } else if (!modelPass) {
                content = '原模特组已更新，无法复用之前的选择，请重选模特'
                title = '无法继续，需重选模特后生成'
            } else if (!posePass) {
                content = '原模特已更新，无法复用之前的选择，请重选模特'
                title = '无法继续，需重选模特后生成'
            }
            console.log('can`t continue...', 2)
            PSAIModal.confirm({
                title: title,
                content: content,
                okText: '去重选',
                cancelText: '取消',
                onOk() {
                    errorCallback ? errorCallback() : reselectModelAndBackground(taskId, imageType, projectName)
                },
                onCancel() {},
            })
            return false
        }
    }).catch(err => {
        message.error(err?.message)
    })
}

const updateInpaintPredictionParamsForRandom = (params, attr, imageType, taskId, projectName) => {
    const { background, commodityFilter, modelFilter, clothCategory } = attr
    const bgApi = imageType == IMAGE_TYPE.COMMODITY ?
                    API.commodityBackgroundList : API.modelBackgroundList
    const modelApi = API.modelPersonList
    return Promise.all([
        modelApi({
            age: modelFilter?.age?.join(),
            sex: modelFilter?.sex?.join(),
            skin: modelFilter?.skin?.join(),
        }),
        bgApi({
            categoryId: clothCategory,
            sceneType: commodityFilter?.sceneType || '',
            commodityType: commodityFilter?.commodityType || ''
        })
    ]).then(([modelRes, bgRes]) => {
        if (!modelRes || modelRes.code != 0 || !modelRes.data) throw new Error(modelRes?.message)
        if (!bgRes || bgRes.code != 0 || !bgRes.data) throw new Error(bgRes?.message)
        
        if (imageType != IMAGE_TYPE.COMMODITY) {
            // 模特随机的keylist
            if (!modelFilter?.age || modelFilter?.age.length == 0) {
                // 不特意删选时，随机模特过滤掉儿童
                params.modelKeyList = modelRes.data
                    .filter(item => item?.attr?.age != 'children' && !/_default_style$/.test(item.name))
                    .filter(item => { // 非真人图需要排除constant_id资源
                        if (imageType != IMAGE_TYPE.MODEL) {
                            return item.attr.algoModelType != "constant_id"
                        } else {
                            return true
                        }
                    })
                    .map(item => item.name) // modalStyle传随机(_default_style)时使用
            } else {
                params.modelKeyList = modelRes.data
                    .filter(item => !/_default_style$/.test(item.name))
                    .filter(item => { // 非真人图需要排除constant_id资源
                        if (imageType != IMAGE_TYPE.MODEL) {
                            return item.attr.algoModelType != "constant_id"
                        } else {
                            return true
                        }
                    })
                    .map(item => item.name) // modalStyle传随机(_default_style)时使用
            } 
        }            
        // 场景随机的keylist
        // 纯色背景
        const solid_background = bgRes.data
            .filter(item => /solid_background/.test(item.name))
            .map(item => [...item.subList.filter(i => !/_solid_background_random$/.test(i.name)).map(i => i.name)])
            .flat()
        // 窗幔布艺
        const drapery_background = bgRes.data
            .filter(item => /drapery_background/.test(item.name))
            .map(item => [...item.subList.filter(i => !/_drapery_background_random$/.test(i.name)).map(i => i.name)])
            .flat()
        // 没有subList的背景
        const no_subList_background = bgRes.data
            .filter(item => (!/_random$/.test(item.name) && !/_keep$/.test(item.name) && !/solid_background/.test(item.name) && !/drapery_background/.test(item.name)))
            .map(item => item.name)
        let bgList = []
        if (/_solid_background_random$/.test(background)) {
            bgList = solid_background
        } else if (/_drapery_background_random$/.test(background)) {
            bgList = drapery_background
        } else {
            bgList = [
                ...no_subList_background,
                ...solid_background,
                ...drapery_background
            ]
        }
        params.bgKeyList = bgList // bgUrl传随机(_random)时使用
        
        if (
            (imageType != IMAGE_TYPE.COMMODITY && params.modelKeyList.length == 0) ||
            params.bgKeyList.length == 0
        ) {
            console.log('can`t continue...', 3)
            PSAIModal.confirm({
                title: '无法继续，需重选模特和场景后生成',
                content: '版本已更新，无法复用之前的选择，请重选模特和场景',
                okText: '去重选',
                cancelText: '取消',
                onOk() {
                    reselectModelAndBackground(taskId, imageType, projectName)
                },
                onCancel() {},
            })
            return false
        }        
        return params
    })
}

// 为复刻操作更新生成参数
const updateInpaintPredictionParamsForReplica = async (params, file, imageType, inpaintAttr) => {
    const maskRes = await maskPrediction(file, imageType)
    if (maskRes) {
        const {id, input, output: segmentResult} = maskRes
        if (imageType == IMAGE_TYPE.MODEL) {
            if (params?.hasOwnProperty('coord_drag')) params.coord_drag = "" // 复刻不能调整背景,需要将coord_drag置空
            params.img_path = segmentResult[0]
            params.mask_path = segmentResult[3]
            params.mask_path_cloth = segmentResult[4]
            params.skin_maskpath = segmentResult[5] || '' // 模特增加手部mask 
            params.hair_maskpath = segmentResult[6] || '' // 模特增加发型mask 
            params.tryon_flag = 0
            // params.scale_rate = Number(params.scale_rate)
        } else if (imageType == IMAGE_TYPE.DRESSFORM) {
            if (params?.hasOwnProperty('coord_drag')) params.coord_drag = ""
            params.img_path = segmentResult[0]
            params.mask_path_cloth = segmentResult[4]
            params.body_mask_path = segmentResult[3]
        } else if (imageType == IMAGE_TYPE.CLOTHES) {
            params.cloth_path = segmentResult[0]
            params.cloth_mask = segmentResult[4]
        }
        return { params: params, maskAlgoId: id, editedOutput: segmentResult, inputImage: input.image }
    }
}
// 复刻的流程
export const workflowOfReplica = async (taskId, imageType, projectName, file, imageAntispamId, errorCallback) => {
    if (imageType == IMAGE_TYPE.COMMODITY || imageType == IMAGE_TYPE.CLOTHES) {
        message.warning('不支持此操作')
        return false
    }
    try {
        const [ver, res] = await Promise.all([
            API.getVersion(), // 单独调用接口，获取version，用来替换以前任务的version
            API.getTaskInfo({ taskId: taskId })
        ])
        if (!res || res.code != 0 || !res.data) throw new Error(res?.message)

        const version = 
            (imageType == IMAGE_TYPE.COMMODITY ? // 商品
                ver?.commodityVersion :
                imageType == IMAGE_TYPE.CLOTHES ? // tryon
                    ver?.tryOnVersion :
                    ver?.modelVersion
            ) ||
            res.data.inpaint?.attr?.version // 兼容写法
        const projectId = res.data.projectId
        const lastInput = res.data.inpaint?.input
        const lastInpaintAttr = res.data.inpaint?.attr // 此处需要检查模特/场景是否有更新
        const lastMask = res.data.mask
        const lastInputImage = lastMask?.input

        const { mote_bg_style, mote_style } = lastInput
        if ((/_random$/.test(mote_bg_style) || /_default_style$/.test(mote_style))) { // 随机时不能复刻
            message.warning('版本已更新，旧版随机生成的任务不支持复用模特/场景')
            return false
        }

        // 检查所选模特/场景/姿势是否存在
        const BgAndModelIsNotExpired = await checkBgAndModelExpired(imageType, taskId, projectName, lastInpaintAttr, lastInput, errorCallback)
        if (!BgAndModelIsNotExpired) return false

        let {isNotExpired, formartParams} = await checkInpaintPredictionParamsExpired({...lastInput, version}, taskId, projectName, imageType, errorCallback)
        if (!isNotExpired) return false

        // 20231010 复刻改成生成4张图，需要重置seed和sub_bgid
        // 20231123 客户反馈复刻生产的模特不一致，改为固定seed和sub_bgid
        // formartParams.seed = -1
        // formartParams.sub_bgid = -1
        // 生成图片数改为1 by ligd20240720
        formartParams.num = 1 // formartParams.num || 4

        // 逐步通过middleware格式化生成参数
        const {extra_property, algo_type} = formartGenerateParams(imageType, {
            refVersion: formartParams.refine_version,
            hairTagText: lastInpaintAttr.hairTagText,
            faceTagText: lastInpaintAttr.faceTagText
        })
        if (imageType == IMAGE_TYPE.MODEL) {
            formartParams.extra_property = extra_property
        }

        // 为复刻操作更新生成参数
        const res2 = await updateInpaintPredictionParamsForReplica(formartParams, file, imageType, lastInpaintAttr)
        if (!res2) return

        const { params, maskAlgoId, editedOutput, inputImage } = res2

        const inpaintAlgo = await inpaintPrediction(params, imageType, true)
        if (!inpaintAlgo) return false
        const projectRes = await projectCreate({
            id: projectId,
            imageType,
            algoType: inpaintAlgo.algoType,
            inpaint: {
                algoId: inpaintAlgo.algoIds,
                attr: {
                    person: lastInput.mote_style,
                    pose: lastInpaintAttr.pose,
                    background: imageType == IMAGE_TYPE.CLOTHES ? lastInput.bkg_style : lastInput.mote_bg_style,
                    version: lastInpaintAttr.version,
                    clothCategory: '',
                    commodityFilter: lastInpaintAttr.commodityFilter,
                    modelFilter: lastInpaintAttr.modelFilter,
                    imgRatio: lastInpaintAttr.imgRatio,
                    faceTagText: lastInpaintAttr.faceTagText,
                    hairTagText: lastInpaintAttr.hairTagText,
                }
            },
            inputImage: inputImage,
            mask: {
                algoId: maskAlgoId,
                editedOutput: editedOutput,
            },
            projectName,
            imageAntispamId
        })
        if (!projectRes) return false

        const {taskList} = projectRes
        const checkRes =  await checkTaskStatus(taskList)
        if (!checkRes) return false
        
        return projectId
    } catch (err) {
        message.error(err?.message)
    }
}

// 更换模特/场景
export const workflowOfChange = async (
    taskId,
    imageType,
    projectName,
    changedModel,
    changedBg,
    filter,
) => {
    if (imageType == IMAGE_TYPE.COMMODITY) {
        message.warning('商品图不支持此操作')
        return false
    }
    try {
        const [ver, res] = await Promise.all([
            API.getVersion(), // 单独调用接口，获取version，用来替换以前任务的version
            API.getTaskInfo({ taskId: taskId })
        ])
        if (!res || res.code != 0 || !res.data) throw new Error(res?.message)

        const version = 
            (imageType == IMAGE_TYPE.COMMODITY ? // 商品
                ver?.commodityVersion :
                imageType == IMAGE_TYPE.CLOTHES ? // tryon
                    ver?.tryOnVersion :
                    ver?.modelVersion
            ) ||    
            res.data.inpaint?.attr?.version // 兼容写法
        const projectId = res.data.projectId
        const lastInput = res.data.inpaint?.input
        const lastInpaintAttr = res.data.inpaint?.attr // 此处需要检查模特/场景是否有更新
        const lastMask = res.data.mask
        const lastInputImage = lastMask?.input
        const recentAlgoId = res.data.inpaint?.algoId

        if (imageType == IMAGE_TYPE.CLOTHES) {
            const outputs = res.data?.inpaint?.outputs
            if (outputs?.length < 3) {
                console.log('can`t continue...', 5)
                PSAIModal.confirm({
                    title: '无法继续，需重选模特和场景后生成',
                    content: '版本已更新，无法复用之前的选择，请重选模特和场景',
                    okText: '去重选',
                    cancelText: '取消',
                    onOk() {
                        reselectModelAndBackground(taskId, imageType, projectName)
                    },
                    onCancel() {},
                })
                return false
            }
            
            const modelInpaintId = res.data?.inpaint?.attr?.modelInpaintId
            if (modelInpaintId) {
                const res2 = await API.getInpaintPredictionById(modelInpaintId)
                if (!res2 || res2.code != 0 || !res2.data) throw new Error(res2?.message)
                const {mote_style, mote_bg_style, seed, sub_bgid} = res2.data.input
                lastInput.mote_style = mote_style // 实际的模特
                lastInput.bkg_style = mote_bg_style // 实际的场景
                lastInput.seed = seed
                lastInput.sub_bgid = sub_bgid
            }
            // if (!modelInpaintId) {
            //     message.warning('当前任务不支持此操作')
            //     return false
            // }
        }

        if (imageType == IMAGE_TYPE.CLOTHES) {
            const { bkg_style, mote_style } = lastInput
            if ((/_random$/.test(bkg_style) || /_default_style$/.test(mote_style))) { // 随机时不能复刻
                message.warning('版本已更新，旧版随机生成的任务不支持更换模特/场景')
                return false
            }
        } else {
            const { mote_bg_style, mote_style } = lastInput
            if ((/_random$/.test(mote_bg_style) || /_default_style$/.test(mote_style))) { // 随机时不能复刻
                message.warning('版本已更新，旧版随机生成的任务不支持更换模特/场景')
                return false
            }
        }

        // 此处需要替换模特/场景
        if (changedModel) {
            lastInpaintAttr.person = changedModel
            lastInpaintAttr.modelFilter = filter
            lastInput.mote_style = changedModel
            if (imageType == IMAGE_TYPE.MODEL) {
                // 为rongrong特殊定制需求，选择rongrong的模特素材，自动改为短发
                if (/_rongrong(id)?/.test(changedModel)) {
                    lastInpaintAttr.hairTagText = '短发'
                }
            }
        }
        if (changedBg) {
            lastInpaintAttr.background = changedBg
            if (imageType == IMAGE_TYPE.CLOTHES) lastInput.bkg_style = changedBg
            else lastInput.mote_bg_style = changedBg

            if (lastInput.defined_flag == 1) { // 自定义场景
                lastInput.defined_flag = 0 // 更换场景，需要将defined_flag置为0
                if (lastInput.prompt) lastInput.prompt = ""
                if (lastInput.bkg_url) lastInput.bkg_url = ""
            }
        }

        // 检查所选模特/场景/姿势是否存在
        const BgAndModelIsNotExpired = await checkBgAndModelExpired(imageType, taskId, projectName, lastInpaintAttr, lastInput)
        if (!BgAndModelIsNotExpired) return false

        let {isNotExpired, formartParams} = await checkInpaintPredictionParamsExpired({...lastInput, version}, taskId, projectName, imageType)
        if (!isNotExpired) return false

        // 如果更换场景，需要重置sub_bgid；
        // 如果更换模特，需要重置seed
        if (changedModel) formartParams.seed = -1
        if (changedBg) formartParams.sub_bgid = -1
        
        // 场景或模特是随机，需要更新参数modelKeyList和bgKeyList
        if (/_random$/.test(changedBg) || /_default_style$/.test(changedModel)) {
            formartParams = await updateInpaintPredictionParamsForRandom(formartParams, lastInpaintAttr, imageType, taskId, projectName)
        }

        // tryon 更换场景生成需要增加参数
        if (imageType == IMAGE_TYPE.CLOTHES) {
            if (changedModel || changedBg) formartParams.recentAlgoId = recentAlgoId
            if (changedModel && formartParams.keepPose == 1) formartParams.keepPose = 0 // 更换模特，如果原来是【保持模特】，需要将【保持模特】属性改为false
            if (changedBg && formartParams.keepBkg == 1) formartParams.keepBkg = 0 // 更换场景，如果原来是【保持场景】，需要将【保持场景】属性改为false
            // 支持自定义模特
            formartParams.t_model = 3 // 取值1,2,3 全身区域分割v0.2已更新，t_model 传3

            // 衣服图的cloth_type目前仅与上传的衣服数量有关：0-上装；1-下装；2-上下装；3-裙装；by ligd 20240517
            if (lastMask.topAlgoId && lastMask.bottomAlgoId) formartParams.cloth_type = 2
            else if (lastMask.topAlgoId) formartParams.cloth_type = 0
            else if (lastMask.bottomAlgoId) formartParams.cloth_type = 1
            else formartParams.cloth_type = 3
        } else if (imageType == IMAGE_TYPE.MODEL) {
            if (changedModel && formartParams.keepPose == 1) formartParams.keepPose = 0 // 更换模特，如果原来是【保持模特】，需要将【保持模特】属性改为false
        }

        // 生成图片数，默认为4
        formartParams.num = formartParams.num || 4

        // 逐步通过middleware格式化生成参数
        const isCustomScene = formartParams.defined_flag == 1
        const isSceneFracturing = formartParams.keep_bkg == 3
        const isSameStyle = formartParams.same_pattern == 1
        const isSimilarStyle = isCustomScene && !isSceneFracturing && !isSameStyle
        const {extra_property, algo_type, keep_bkg} = formartGenerateParams(imageType, {
            bgStyle: formartParams.mote_bg_style || formartParams.bkg_style,
            isCustomScene: isCustomScene,
            isSceneFracturing: isSceneFracturing,
            isSameStyle: isSameStyle, // 拍同款
            isSimilarStyle: isSimilarStyle, // 相似风格
            refVersion: formartParams.refine_version,
            hairTagText: lastInpaintAttr.hairTagText,
            faceTagText: lastInpaintAttr.faceTagText,
        })
        if (imageType == IMAGE_TYPE.MODEL || imageType == IMAGE_TYPE.DRESSFORM) {  
            formartParams.extra_property = extra_property
            formartParams.keep_bkg = keep_bkg
        }

        const inpaintAlgo = await inpaintPrediction(formartParams, imageType, true)
        if (!inpaintAlgo) return false

        const projectRes = await projectCreate({
            id: projectId,
            imageType,
            algoType: inpaintAlgo.algoType,
            inpaint: {
                algoId: inpaintAlgo.algoIds,
                attr: {
                    person: lastInput.mote_style,
                    pose: lastInpaintAttr.pose,
                    background: imageType == IMAGE_TYPE.CLOTHES ? lastInput.bkg_style : lastInput.mote_bg_style,
                    version: lastInpaintAttr.version,
                    clothCategory: '',
                    commodityFilter: lastInpaintAttr.commodityFilter,
                    modelFilter: lastInpaintAttr.modelFilter,
                    imgRatio: lastInpaintAttr.imgRatio,
                    faceTagText: lastInpaintAttr.faceTagText,
                    hairTagText: lastInpaintAttr.hairTagText,
                }
            },
            inputImage: lastInputImage,
            mask: lastMask,
            projectName,
        })
        if (!projectRes) return false

        const {taskList} = projectRes
        const checkRes =  await checkTaskStatus(taskList)
        if (!checkRes) return false
        
        return projectId
    } catch (err) {
        message.error(err?.message)
    }
}

// 重新生成的流程
export const workflowOfRebuild = async (taskId, imageType, projectName) => {
    try {
        const [ver, res] = await Promise.all([
            API.getVersion(), // 单独调用接口，获取version，用来替换以前任务的version
            API.getTaskInfo({ taskId: taskId })
        ])
        if (!res || res.code != 0 || !res.data) throw new Error(res?.message)

        const version = 
            (imageType == IMAGE_TYPE.COMMODITY ? // 商品
                ver?.commodityVersion :
                imageType == IMAGE_TYPE.CLOTHES ? // tryon
                    ver?.tryOnVersion :
                    ver?.modelVersion
            ) ||
            res.data.inpaint?.attr?.version // 兼容写法
        const projectId = res.data.projectId
        const lastInput = res.data.inpaint?.input
        const lastInpaintAttr = res.data.inpaint?.attr // 此处需要检查模特/场景是否有更新
        const lastMask = res.data.mask
        const lastInputImage = lastMask?.input
        const recentAlgoId = res.data.inpaint?.algoId

        // 检查所选模特/场景/姿势是否存在
        const BgAndModelIsNotExpired = await checkBgAndModelExpired(imageType, taskId, projectName, lastInpaintAttr)
        if (!BgAndModelIsNotExpired) return false

        if (imageType == IMAGE_TYPE.CLOTHES) {
            const outputs = res.data?.inpaint?.outputs
            if (outputs?.length < 3) {
                console.log('can`t continue...', 6)
                PSAIModal.confirm({
                    title: '无法继续，需重选模特和场景后生成',
                    content: '版本已更新，无法复用之前的选择，请重选模特和场景',
                    okText: '去重选',
                    cancelText: '取消',
                    onOk() {
                        reselectModelAndBackground(taskId, imageType, projectName)
                    },
                    onCancel() {},
                })
                return false
            }

            const modelInpaintId = res.data?.inpaint?.attr?.modelInpaintId
            if (modelInpaintId) {
                const res2 = await API.getInpaintPredictionById(modelInpaintId)
                if (!res2 || res2.code != 0 || !res2.data) throw new Error(res2?.message)
                const {mote_style, mote_bg_style, seed, sub_bgid} = res2.data.input
                lastInput.mote_style = mote_style // 实际的模特
                lastInput.bkg_style = mote_bg_style // 实际的场景
                // lastInput.seed = seed
            }
            // if (!modelInpaintId) {
            //     message.warning('当前任务不支持此操作')
            //     return false
            // }
        }

        let {isNotExpired, formartParams} = await checkInpaintPredictionParamsExpired({...lastInput, version}, taskId, projectName, imageType)
        if (!isNotExpired) return false

        // 需要重置seed和sub_bgid
        formartParams.seed = -1
        formartParams.sub_bgid = -1

        // 场景或模特是随机，需要更新参数modelKeyList和bgKeyList
        const { person, background, modelFilter } = lastInpaintAttr
        if (/_random$/.test(background) || /_default_style$/.test(person)) {
            // 重新生成时,如果是随机,需要将mote_style和mote_bg_style重置为person和background
            if (imageType == IMAGE_TYPE.CLOTHES) {
                formartParams.bkg_style = background
                formartParams.mote_style = person
            } else if (imageType == IMAGE_TYPE.COMMODITY) {
                formartParams.goods_bg_style = background
            } else {
                formartParams.mote_bg_style = background
                formartParams.mote_style = person
            }
            formartParams = await updateInpaintPredictionParamsForRandom(formartParams, lastInpaintAttr, imageType, taskId, projectName)
        }

        if (!formartParams) return false

        // tryon 生成更多需要增加参数
        if (imageType == IMAGE_TYPE.CLOTHES) {
            formartParams.recentAlgoId = recentAlgoId

            // 衣服图的cloth_type目前仅与上传的衣服数量有关：0-上装；1-下装；2-上下装；3-裙装；by ligd 20240517
            if (lastMask.topAlgoId && lastMask.bottomAlgoId) formartParams.cloth_type = 2
            else if (lastMask.topAlgoId) formartParams.cloth_type = 0
            else if (lastMask.bottomAlgoId) formartParams.cloth_type = 1
            else formartParams.cloth_type = 3
        }

        // 生成图片数，默认为4
        formartParams.num = formartParams.num || 4

        // 逐步通过middleware格式化生成参数
        const {extra_property, algo_type} = formartGenerateParams(imageType, {
            refVersion: formartParams.refine_version,
            hairTagText: lastInpaintAttr.hairTagText,
            faceTagText: lastInpaintAttr.faceTagText
        })
        if (imageType == IMAGE_TYPE.MODEL) {
            formartParams.extra_property = extra_property
        }
        
        const inpaintAlgo = await inpaintPrediction(formartParams, imageType)
        if (!inpaintAlgo) return false
        const projectRes = await projectCreate({
            id: projectId,
            imageType,
            algoType: inpaintAlgo.algoType,
            inpaint: {
                algoId: inpaintAlgo.algoIds,
                attr: {
                    person: lastInpaintAttr.person,
                    pose: lastInpaintAttr.pose,
                    background: lastInpaintAttr.background,
                    version: lastInpaintAttr.version,
                    clothCategory: '',
                    commodityFilter: lastInpaintAttr.commodityFilter,
                    modelFilter: lastInpaintAttr.modelFilter,
                    imgRatio: lastInpaintAttr.imgRatio,
                    faceTagText: lastInpaintAttr.faceTagText,
                    hairTagText: lastInpaintAttr.hairTagText,
                }
            },
            inputImage: lastInputImage,
            mask: lastMask,
            projectName,
        })
        if (!projectRes) return false
        
        const {taskList} = projectRes
        return await checkTaskStatus(taskList)
    } catch (err) {
        message.error(err?.message)
    }
}

// 生成流程
export const workflowOfBuild = async ({
    imageType,
    data,
    option,
    extraData,
}) => {
    try {
        const {
            projectId, // 项目id，由于继续生成
            projectName, // 项目name
            version, // 素材version
            inputImage, // 输入图
            inputImageTop, // 衣服上装输入图
            inputImageBottom, // 衣服下装输入图
            segmentResult, // 分割图mask
            segmentResult1, // 衣服上装的分割图mask
            segmentResult2, // 衣服下装的分割图mask
            maskAlgoId, // 分割图的算法id
            maskAlgoId1, // 衣服上装的分割图的算法id
            maskAlgoId2, // 衣服下装的分割图的算法id
            modelStyle, // 模特key
            modelKeyList, // 随机时模特的keylist
            bgStyle, // 场景key
            bgKeyList, // 随机时场景的keylist
            poseStyle, // 姿势key
            isSelfDefined, // 自定义姿势图
            selfDefinedImg, // 自定义姿势图的url
            keepPose, // 保持姿势
            keepBkg, // 保持场景
            hairTagText, // 发型特征
            faceTagText, // 表情特征
            positivePrompt, // 自定义描述词
            negativePrompt, // 自定义描述词（负面）
            refImg, // 自定义场景
            refImgMask, // 自定义场景的mask-商品拍同款/真人拍同款
            refImgErased, // 自定义场景参考图擦除后的图-真人拍同款
            refStrength, // 自定义场景的强度
            createNum, // 生成数量
            imgWidth, // 生成图像尺寸-width
            imgHeight, // 生成图像尺寸-height
            definedFlag, // 1-自定义场景
            refVersion, // 商品：1-背景合成,2-相似风格,3-拍同款。模特：1-相似风格,2-场景裂变
            dpScale, // 场景裂变强度
            clothType, // 用来区分ai试衣是套装还是连体装
            imgRatio, // 商品/模特图的图像比例
            scaleRate, // 模特图的模特缩放比例
            ltX, // 模特图的模特位置参数
            ltY, // 模特图的模特位置参数
            coordDrag, // 模特图的背景剪裁位置尺寸参数
            subBgId, // 算法端使用的参考背景index，已固定为0
            dragScale, // 商品图的商品位置尺寸参数和softedge位置尺寸参数
            outputMaxSize, // 决定商品生成参数upscale_enable是1/0
            commodityFilter, // 商品的筛选项
            modelFilter, // 模特筛选项
            imageAntispamId, // 上传图的审核id
        } = data
       
        const {
            isCustomScene, // 自定义场景
            isSceneFracturing, // 场景裂变
            isSameStyle, // 拍同款
            isSimilarStyle, // 相似风格
            maskMoveable,
        } = option
        const {
            imgClipSize, // 模特图的图像剪裁尺寸
        } = extraData
    
        let segmentResult0OffsetImg = segmentResult[0]
        let segmentResult3OffsetImg = segmentResult[3]
        let segmentResult4OffsetImg = segmentResult[4]
        let segmentResult5OffsetImg = segmentResult[5]
        let segmentResult6OffsetImg = segmentResult[6]
        // 保持原图场景/场景裂变，使用原图mask
        if ((imageType == IMAGE_TYPE.MODEL || imageType == IMAGE_TYPE.DRESSFORM) && maskMoveable) {
            // 制作偏移图像
            const base64List = await Promise.all([
                Utils.makeOffsetImgByFabric(segmentResult[0], scaleRate, ltX, ltY, imgClipSize.width, imgClipSize.height),
                Utils.makeOffsetImgByFabric(segmentResult[3], scaleRate, ltX, ltY, imgClipSize.width, imgClipSize.height),
                Utils.makeOffsetImgByFabric(segmentResult[4], scaleRate, ltX, ltY, imgClipSize.width, imgClipSize.height),
                Utils.makeOffsetImgByFabric(segmentResult[5], scaleRate, ltX, ltY, imgClipSize.width, imgClipSize.height),
                Utils.makeOffsetImgByFabric(segmentResult[6], scaleRate, ltX, ltY, imgClipSize.width, imgClipSize.height),
            ])
            const list = await Promise.all(base64List.map(base64 => Utils.uploadBase64Image(base64)))
            segmentResult0OffsetImg = list[0]
            segmentResult3OffsetImg = list[1]
            segmentResult4OffsetImg = list[2]
            segmentResult5OffsetImg = list[3]
            segmentResult6OffsetImg = list[4]

            // segmentResult0OffsetImg = await Utils.uploadBase64Image(await Utils.makeOffsetImgByFabric(segmentResult[0], scaleRate, ltX, ltY, imgClipSize.width, imgClipSize.height))
            // segmentResult3OffsetImg = await Utils.uploadBase64Image(await Utils.makeOffsetImgByFabric(segmentResult[3], scaleRate, ltX, ltY, imgClipSize.width, imgClipSize.height))
            // segmentResult4OffsetImg = await Utils.uploadBase64Image(await Utils.makeOffsetImgByFabric(segmentResult[4], scaleRate, ltX, ltY, imgClipSize.width, imgClipSize.height))
            // segmentResult5OffsetImg = await Utils.uploadBase64Image(await Utils.makeOffsetImgByFabric(segmentResult[5], scaleRate, ltX, ltY, imgClipSize.width, imgClipSize.height))
            // segmentResult6OffsetImg = await Utils.uploadBase64Image(await Utils.makeOffsetImgByFabric(segmentResult[6], scaleRate, ltX, ltY, imgClipSize.width, imgClipSize.height))
        }
        
        const {
            extra_property,
            algo_type, // 算法类型
            keep_bkg,
        } = formartGenerateParams(imageType, {            
            bgStyle,
            isCustomScene,
            isSceneFracturing, // 场景裂变
            isSameStyle, // 拍同款
            isSimilarStyle, // 相似风格
            refVersion,
            hairTagText,
            faceTagText,
        })
    
        let obj = imageType == IMAGE_TYPE.MODEL? {
            img_path: segmentResult0OffsetImg, //segmentResult[0],  // 分割结果
            mask_path: segmentResult3OffsetImg, // segmentResult[3],
            mask_path_cloth: segmentResult4OffsetImg, // segmentResult[4],
            lt_x: ltX || 0,
            lt_y: ltY || 0,
            scale_rate: scaleRate,
            coord_drag: coordDrag ? JSON.stringify(coordDrag) : "",
            prompt: positivePrompt, // 自定义提示词
            negative_prompt: negativePrompt, //负提示词  当前是写死
            seed: -1,
            sub_bgid: subBgId,  // 场景内部子index，目前只能为-1
            bkg_url: refImg, // 自定义场景参考图
            bkg_url_mask: refImgMask, // 自定义场景参考图mask
            bkg_url_erased: refImgErased, // 自定义场景参考图擦除后的图
            same_pattern: isSameStyle ? 1 : 0, // 拍同款标识
            ip_scale: refStrength, // 自定义场景强度
            steps: 30,
            cfg: 7,
            defined_flag: definedFlag, // 自定义场景1，否则为0
            mote_style: modelStyle || "", // 模特
            mote_bg_style: bgStyle || "", // 背景
            version: version, // 模特/人台算法增加字段version
            upscale_enable: 0,
            faceRestore_enable: 1,
            style_info: 1,
            faceshape_style: '', // 新增参数，默认传空
            modelKeyList: modelKeyList, // modalStyle传随机(_default_style)时使用
            bgKeyList: bgKeyList, // bgUrl传随机(_random)时使用
            gender: null,
            cloth_type: 2,  // 来自服装类型的选择，仅用于衣服图的区分 默认2
            skin_maskpath: segmentResult5OffsetImg, // segmentResult[5] || '', // 模特增加手部mask
            hair_maskpath: segmentResult6OffsetImg, // segmentResult[6] || '', // 模特增加发型mask
            tryon_flag: 0,
            num: createNum, //生成几张
            extra_property: extra_property, // 特征
            keep_bkg: keep_bkg, // 仅重绘皮肤区域
            dp_scale: dpScale, // 场景裂变
            keepPose: keepPose ? 1 : 0, // 不更换容貌
        } : imageType == IMAGE_TYPE.COMMODITY? {
            img_path: segmentResult[0],
            mask_path: segmentResult[4],
            imgs_dict: JSON.stringify(dragScale), // 这是商品在画布上的位置，旋转，层级，大小等信息，可以在浏览器里面拷贝出来参考
            p_prompt: positivePrompt, // 自定义场景提示词
            n_prompt: "",
            sample_mtd: "Eular a",
            seed: -1,
            ref_img: refImg, // 自定义场景参考图
            ref_img_mask: refImgMask, // 自定义场景参考图mask
            refine_version: refVersion, // v1-生成同款 v2-相似风格  下两个参数是自定义参考图两个强度，同款和相似风格
            ref_strength: (refVersion == 1 || refVersion == 3) ? refStrength : 0.3, // v1, default 0.3  
            ref_prompt_strength: refVersion == 2 ? refStrength : 0.7, // v2, default 0.7
            steps: 20,
            cfg: 7,
            defined_flag: definedFlag, // 自定义场景1，否则为0 
            goods_bg_style: bgStyle || "", //背景
            bgKeyList: bgKeyList, // bgUrl传随机(_random)时使用
            version: version, // 算法增加字段version
            upscale_enable: outputMaxSize >= 1024 ? 1: 0, 
            num: createNum,
            canvas_width: imgWidth, //画布宽高，对应生成图大小
            canvas_height: imgHeight,
        }: {
            img_path: segmentResult0OffsetImg, // segmentResult[0],
            body_mask_path: segmentResult3OffsetImg, //segmentResult[3] // mask_path
            mask_path_cloth: segmentResult4OffsetImg, //segmentResult[4]
            lt_x: ltX || 0,
            lt_y: ltY || 0,
            scale_rate: scaleRate,
            coord_drag: coordDrag ? JSON.stringify(coordDrag) : "", // 背景移动参数
            prompt: positivePrompt, //自定义场景提示词，不传就是默认
            negative_prompt: negativePrompt,
            seed: -1,
            sub_bgid: subBgId,  // 场景内部子index，目前只能为-1
            bkg_url: refImg, // 自定义场景参考图，没有前端就默认传个URl，前
            ip_scale: refStrength, // 自定义场景强度
            steps: 20,
            cfg: 7,
            defined_flag: definedFlag, // 场景内部子index，目前只能为-1
            mote_style: modelStyle || "",
            mote_bg_style: bgStyle || "",
            version: version, // 算法增加字段version
            upscale_enable: 0,
            faceRestore_enable: 1,
            // style_info
            faceshape_style: '', // 新增参数，默认传空
            modelKeyList: modelKeyList, // modalStyle传随机(_default_style)时使用
            bgKeyList: bgKeyList, // bgUrl传随机(_random)时使用
            gender: null, 
            cloth_type: 2, //固定为2 
            // skin_maskpath
            // hair_maskpath
            // tryon_flag
            num: createNum,
            extra_property: extra_property, // 特征
            keep_bkg: keep_bkg, // 仅重绘皮肤区域
            dp_scale: dpScale, // 场景裂变
            // keepPose  // 不更换容貌
        }
        let mask = {algoId: maskAlgoId, editedOutput: segmentResult}
        let input_image = inputImage || segmentResult[0]
        // virtualtryon功能测试
        if (imageType == IMAGE_TYPE.CLOTHES) {
            let _clothType = 0 // 衣服图的cloth_type目前仅与上传的衣服数量有关：0-上装；1-下装；2-上下装；3-裙装；by ligd 20240517
            let cloth_path = segmentResult[0]
            let cloth_mask = segmentResult[4]
            if (clothType == CLOTH_TYPE.SUIT) { // 套装
                if (segmentResult1.length == 0 && segmentResult2.length == 0) return message.warning('请至少上传一件衣服')
                if (segmentResult1.length == 0) { // 只传了下装
                    _clothType = 1
                    input_image = inputImageBottom || segmentResult2[0]
                    cloth_path = segmentResult2[0]
                    cloth_mask = segmentResult2[4]
                    mask = {bottomAlgoId: maskAlgoId2, bottomEditedOutput: segmentResult2}
                } else if (segmentResult2.length == 0) { // 只传了上衣
                    _clothType = 0
                    input_image = inputImageTop || segmentResult1[0]
                    cloth_path = segmentResult1[0]
                    cloth_mask = segmentResult1[4]
                    mask = {topAlgoId: maskAlgoId1, topEditedOutput: segmentResult1}
                } else { // 套装，上衣下装都传了
                    _clothType = 2
                    input_image = (inputImageTop && inputImageBottom) ? `${inputImageTop},${inputImageBottom}` : `${segmentResult1[0]},${segmentResult2[0]}`
                    cloth_path = `${segmentResult1[0]},${segmentResult2[0]}`
                    cloth_mask = `${segmentResult1[4]},${segmentResult2[4]}`
                    mask = {
                        topAlgoId: maskAlgoId1,
                        topEditedOutput: segmentResult1,
                        bottomAlgoId: maskAlgoId2,
                        bottomEditedOutput: segmentResult2
                    }
                }
            } else { // 连体装
                _clothType = 3
            }
            obj = {
                cloth_path: cloth_path,
                mote_style: modelStyle || "", // 模特
                modelKeyList: modelKeyList, // modalStyle传随机(_default_style)时使用
                person_style: poseStyle || "", // 姿势
                cloth_mask: cloth_mask,
                version: version,
                bkg_style: bgStyle || "",
                bgKeyList: bgKeyList, // bgUrl传随机(_random)时使用
                gender: null, // 由于上传页面删除了服装类别，gender传null由服务端处理
                cloth_type: _clothType,
                seed: -1, // model采用的是tryon的模特，seed目前无效
                sub_bgid: subBgId,
                num: createNum,
                t_model: 3, // 取值1,2,3 全身区域分割v0.2已更新，t_model 传3
                self_defined: isSelfDefined ? 1 : 0, //【0-预置模特，1-自定义】
                mote_img: isSelfDefined ? selfDefinedImg : '', // 用户上传得模特图，用于构建模特
                keepPose: keepPose ? 1 : 0, // 不更换容貌
                keepBkg: keepBkg ? 1 : 0, // 不更换场景
            }
        }
    
        // console.log(obj)
        // return 
    
        const inpaintAlgo = await inpaintPrediction(obj, imageType, false)
        if (!inpaintAlgo) return false
        let proInfo = {
            id: projectId,
            projectName: projectName,
            imageType: imageType,
            algoType: inpaintAlgo.algoType,
            inputImage: input_image,
            mask: {
                algoId: "",
                editedOutput: [],
                topAlgoId: "",
                topEditedOutput: [],
                bottomAlgoId: "",
                bottomEditedOutput: [],
                ...mask
            },
            inpaint: {
                algoId: inpaintAlgo.algoIds,
                attr: {
                    person: modelStyle || "",
                    pose: poseStyle || "",
                    background: bgStyle || "",
                    version: version,
                    clothCategory: '',
                    commodityFilter: commodityFilter,
                    modelFilter: modelFilter,
                    imgRatio: imgRatio,
                    faceTagText: faceTagText,
                    hairTagText: hairTagText,
                }
            },
            imageAntispamId: imageAntispamId || ''
        }
        const projectRes = await projectCreate(proInfo)
        if (!projectRes) return false
    
        const {id, taskList} = projectRes
        const checkRes =  await checkTaskStatus(taskList)
        if (!checkRes) return false
        
        return id
    } catch (err) {
       console.error(err)
       message.error(err.message) 
    }
}

export default {
    projectCreate,
    checkTaskStatus,
    workflowOfReplica,
    workflowOfRebuild,
    workflowOfChange,
    workflowOfBuild,
}