import { useEffect, useMemo, useState } from 'react'
import styles from './index.module.scss'
import { IMAGE_TYPE } from '@utils/CONST'
import { Button, Image, Upload, message } from 'antd'
import Utils from '@utils/utils'
import {PlusOutlined} from '@ant-design/icons';
import GroupTitleContent from '../GroupTitleContent'

function UploadItem (props) {
    const [url, setUrl] = useState()
    useEffect(() => {
        if (props.uid) {
            Utils.convertFileToBase64(props.file).then(res => setUrl(res))
        } else {
            setUrl()
        }
    }, [props.uid, props.file])

    const handleBeforeUpload = async (file) => {
        const res = await Utils.validateImageFile(file)
        if (res) {
            props.onUpload(props.index, {
                uid: file.uid,
                originFileObj: file
            })
        }
        return false
    }

    const handleRemove = (e) => {
        e.stopPropagation()
        props.onRemove(props.index)
    }

    return (
        <div className={styles.UploadItem}>
            <Upload
                className={styles.EmptyUpload}
                accept='image/jpeg,image/png,image/webp,image/avif'
                listType="picture-card"
                showUploadList={false}
                beforeUpload={handleBeforeUpload}
            >
            {
                url ?
                <div className={styles.UploadContent}>
                    <Image
                        width={"100%"}
                        height={"100%"}
                        preview={false}
                        src={url}
                    />
                    <i className={styles.del_btn} onClick={handleRemove}></i>
                </div> :
                <div className={styles.UploadContent}>
                    <PlusOutlined style={{fontSize: 24}} />
                </div>
            }
            </Upload>
        </div>
    )
}

export default function ReplicaForm (props) {
    const [fileList, setFileList] = useState([])
    const handleBeforeUpload = () => {
        return false
    }
    const handleChange = async({file, fileList}) => {
        const res = await Utils.validateImageFile(file)
        if (res) {
            setFileList(pre => {
                const notEmptyPre = pre.filter(item => item)
                const list = [...notEmptyPre, {
                    uid: file.uid,
                    originFileObj: file
                }]
                return [...list, ...new Array(4 - list.length).fill()]
            })
        }
        // const list = [...fileList, ...new Array(4 - fileList.length).fill()]
        // setFileList(list)
    }

    const EmptyUploadContent = (
        <div className={styles.EmptyUploadContent}>
            <p>可同时为多张图片应用和左图相同的场景和模特</p>
            <Button type="primary" >上传模特图</Button>
            <p>
                <span>点击按钮或拖拽图片至此区域上传，最多可同时上传4张</span>
                <span>建议图片尺寸不小于512*512，长宽比不超过2:1</span>
            </p>
        </div>
    )
    
    const handleUpload = (index, file) => {
        setFileList(pre => {
            pre.splice(index, 1, file)
            const list = [...pre, ...new Array(4 - pre.length).fill()]
            return list
        })
    }

    const handleRemove = (index) => {
        setFileList(pre => {
            pre.splice(index, 1)
            if (pre.filter(item => item).length > 0) {
                const list = [...pre, ...new Array(4 - pre.length).fill()]
                return list
            } else {
                return []
            }            
        })
    }

    useEffect(() => {
        props.onChange(fileList.filter(item => item).map(item => item.originFileObj))
    }, [fileList])

    return (
        <div className={styles.Container}>
            <div className={styles.Title}>
                复刻图像 - 
                <GroupTitleContent
                    useReal
                    imageType={props.record.imageType}
                    personTitle={props.record.personTitle}
                    personRealTitle={props.record.personRealTitle}
                    backgroundTitle={props.record.backgroundTitle}
                    backgroundRealTitle={props.record.backgroundRealTitle}
                    videoTryonTitle={props.record.videoTryonTitle}
                    refImg={props.record.refImg}
                    definedFlag={props.record.definedFlag}
                    keepPose={props.record.keepPose}
                    keepBkg={props.record.keepBkg}
                />
            </div>
            <div className={styles.Content}>
                <div className={styles.ReplicaOf}>
                    <Image
                        width={"100%"}
                        height={"100%"}
                        preview={false}
                        src={Utils.getImgUrlWithWebp(props.record.image)}
                    />
                </div>
                <div className={styles.ReplicaUpload}>
                {
                    fileList.length == 0 ? 
                    <Upload
                        multiple
                        maxCount={4}
                        className={styles.EmptyUpload}
                        accept='image/jpeg,image/png,image/webp,image/avif'
                        listType="picture-card"
                        fileList={fileList}
                        showUploadList={false}
                        beforeUpload={handleBeforeUpload}
                        onChange={handleChange}
                    >
                        {EmptyUploadContent}
                    </Upload> :
                    <div className={styles.UploadList}>
                    {
                        fileList.map((item, index) => (
                            <UploadItem
                                key={item ? item.uid : index}
                                index={index}
                                uid={item?.uid}
                                file={item?.originFileObj}
                                onUpload={handleUpload}
                                onRemove={handleRemove}
                            />
                        ))
                    }
                    </div>
                }
                </div>
            </div>
        </div>
    )
}