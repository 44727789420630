import React, {useState, useEffect, useRef, useContext, useMemo} from 'react';
import { message, Radio, Checkbox, Select, Col, Input, InputNumber, Upload, Button, Divider, Popover, Empty, Spin } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import Utils from '@utils/utils'
import API from '@api/api'
import { AppContext } from '@utils/AppContext';
import { GenerateContext } from "@utils/GenerateContext"
import styles from './index.module.scss'
import { IMAGE_TYPE, PRIVATE_ABILITIES } from "@utils/CONST"
import VersionTips from '../CustomScene/components/VersionTips';
import SelectionItem from '../SelectionItem';

export default function SceneSelection (props) {
    const {
        isProEnv,
        filteredBgListRef,
        hasPrivateAbility,
    } = useContext(AppContext);
    const {
        imageType,
        setBgImage,
        commodityFilter,
        keepBkg, setKeepBkg, // tryon不更换场景
        scenelDate, // 场景列表 
        setScenelList, // 设置场景列表
    } = useContext(GenerateContext);
    const [loading, setLoading] = useState(true)
    
    // 筛选场景类别/商品类别
    const [sceneType, setSceneType] = useState(commodityFilter?.sceneType)
    const [commodityType, setCommodityType] = useState(commodityFilter?.commodityType)
    useEffect(() => {
        setSceneType(commodityFilter.sceneType)
        setCommodityType(commodityFilter.commodityType)
    }, [commodityFilter])

    const [keepBkgItem2] = useState({
        id: 'keep_bkg',
        name: '00_keep',
        imageUrl: 'https://photostudio-ai-oss.arcsoft.com.cn/photostudio-web/admin/icon_keep_bkg.png',
        attr: {title: '不更换场景'},
    })
    const [keepBkgItem] = useState({
        id: 'keep_bkg',
        name: '__keep_bkg',
        imageUrl: 'https://photostudio-ai-oss.arcsoft.com.cn/photostudio-web/admin/icon_keep_bkg.png',
        attr: {title: '不更换场景'},
        isKeepBkg: true
    })

    const fetchData = () => {
        let getBackgroundApi = imageType == IMAGE_TYPE.COMMODITY ?
                API.commodityBackgroundList : API.modelBackgroundList
        let params = { imageType: imageType }
        if (sceneType) params.sceneType = sceneType
        if (commodityType) params.commodityType = commodityType
        setLoading(true)
        return getBackgroundApi(params).then(res => {
            if (res.code != 0) throw new Error(res.message)
            // 场景随机的key是00_random，需要过滤掉
            // 保持原图场景的key是00_keep，需要过滤掉
            const list = res.data
                .filter(item => item.name != '00_random' && item.name != '00_keep')
                .map(item => {
                    if (props.backgroundType == 0 || props.backgroundType == 1) {
                        if (item.attr.backgroundType == 0) return item
                        else { // 因为窗幔布艺目前属于参考图，需要过滤掉随机icon
                            if (/drapery_background/.test(item.name)) {
                                return {
                                    ...item,
                                    subList: item.subList.filter(item => item.name !== '00_drapery_background_random')
                                }
                            } else {
                                return item
                            }
                        }
                    } else {
                        return item
                    }                    
                })
            setScenelList(list)
            return list
        }).catch((err) => {
            console.error(err)
            message.warning(err.message)
        }).finally(() => {
            setLoading(false)
        })
    }
    // 检查资源是否还存在，仅执行一次
    const noticeOnceRef = useRef(true) // 未找到素材的提示仅出现一次
    const checkIsExist = (list, sceneName) => {
        let found = (sceneName == '00_random' || sceneName == '00_keep') ?
            {name: sceneName, attr: {backgroundType: 0}} :
            false
        if (!found) {
            // 检查是否包含 props.poseName 对应的项，找不到的话需要弹窗提示
            for (let i = 0; i < list.length; i++) {
                if (list[i].name === sceneName) {
                    found = list[i];
                    break;
                }
                let subList = list[i].subList
                if (subList) {
                    for (let j = 0; j < subList.length; j++) {
                        if (subList[j].name === sceneName) {
                            found = subList[j];
                            break
                        }
                    }
                }
            }
        }
        if (noticeOnceRef.current) {
            noticeOnceRef.current = false
            if (found) props.foundSceneFunc()
            else props.notFoundSceneFunc()
        }
        return found
    }
    // 将sublist内部的选择项移到外面
    const switchSubToMain = (list, sceneName) => {
        if (sceneName == '00_random' || sceneName == '00_keep') return false
        let found = false
        for (let i = 0; i < list.length; i++) {
            if (list[i].name === sceneName) break
            let subList = list[i].subList
            if (subList) {
                for (let j = 0; j < subList.length; j++) {
                    if (subList[j].name === sceneName) {
                        list[i].name = sceneName
                        list[i].id = subList[j].id
                        list[i].imageUrl = subList[j].imageUrl
                        list[i].attr.refUrl = subList[j].attr.refUrl
                        found = true
                        break
                    }
                }
            }
            if (found) break
        }
        if (found) return list
        else return false
    }
    // 初始化场景
    const initedRef = useRef(false)
    useEffect(() => {
        fetchData().then(list => {
            if (!list) return
            if (initedRef.current) return
            initedRef.current = true
        })
    }, [sceneType, commodityType, props.backgroundType, props.refreshRecent])

    useEffect(() => {
        if (!scenelDate) return
        if (keepBkg) return
        const isExist = props.sceneName && checkIsExist(scenelDate, props.sceneName)
        if (!isExist || !props.sceneName) {
            let firstItem
            if (props.backgroundType == 0 || props.backgroundType == 1) {
                firstItem = scenelDate.filter(item => item.attr.backgroundType == props.backgroundType)[0]
            } else {
                firstItem = scenelDate[0]
            }
            props.onSceneChange(firstItem?.name)
        }
        if (props.sceneName) {
            const list = switchSubToMain(scenelDate, props.sceneName)
            if (list) setScenelList([...list])
        }
    }, [scenelDate, props.sceneName, keepBkg])

    // 选择某个主体
    const selectImgClick = (item) => {
        if (item.isKeepBkg) {
            setKeepBkg(true)
            props.onSceneChange()
        } else {
            setKeepBkg(false)
            props.onSceneChange(item.name)
        }
    }

    // subList选中时替换主体的name和url
    const onMoreSceneChange = (item) => {
        setKeepBkg(false)
        props.onSceneChange(item.name)
    };
    
    useEffect(() => {
        if (!scenelDate) return
        // 纯色背景
        const solid_background = scenelDate
            .filter(item => /solid_background/.test(item.name))
            .map(item => [...item.subList.filter(i => !/_solid_background_random$/.test(i.name)).map(i => i.name)])
            .flat()
        // 窗幔布艺
        const drapery_background = scenelDate
            .filter(item => /drapery_background/.test(item.name))
            .map(item => [...item.subList.filter(i => !/_drapery_background_random$/.test(i.name)).map(i => i.name)])
            .flat()
        // 没有subList的背景
        const no_subList_background = scenelDate
            .filter(item => (!/_random$/.test(item.name) && !/_keep$/.test(item.name) &&!/solid_background/.test(item.name) && !/drapery_background/.test(item.name)))
            .map(item => item.name)
        if (/_solid_background_random$/.test(props.sceneName)) { // 纯色背景
            filteredBgListRef.current = solid_background
        } else if (/_drapery_background_random$/.test(props.sceneName)) { // 窗幔布艺
            filteredBgListRef.current = drapery_background
        } else { // 包含所有的
            filteredBgListRef.current = [
                ...no_subList_background,
                ...solid_background,
                ...drapery_background
            ]
        }
    }, [scenelDate, props.sceneName])

    const showKeepBg = useMemo(() => {
        if (imageType == IMAGE_TYPE.CLOTHES) return !isProEnv || hasPrivateAbility(PRIVATE_ABILITIES.TRYON_KEEP_BKG)
        else if (imageType == IMAGE_TYPE.MODEL || imageType == IMAGE_TYPE.DRESSFORM) return props.backgroundType == 0
        else return false
    }, [imageType, isProEnv, props.backgroundType, hasPrivateAbility])

    const keepBgItem = useMemo(() => {
        return imageType == IMAGE_TYPE.CLOTHES ? keepBkgItem : keepBkgItem2
    }, [imageType, keepBkgItem, keepBkgItem2])

    const showList = useMemo(() => {
        if (!scenelDate) return []
        const showCount = props.visibleCount // 显示的item数量
        const showCountWithoutResident = showKeepBg ? showCount-1 : showCount // 显示的非常驻的资源数量
        let thisTypeData = scenelDate
        if (props.backgroundType == 0 || props.backgroundType == 1) {
            thisTypeData = scenelDate.filter(item => item.attr.backgroundType == props.backgroundType)
        }
        const findIndex = thisTypeData
            .findIndex(item => {
                if (item.name == props.sceneName) return true
                if (item.subList?.find(subitem => subitem.name == props.sceneName)) return true
            })
        if (findIndex >= 0) {
            if (findIndex < showCountWithoutResident) {
                if (showKeepBg) {
                    return [keepBgItem, ...thisTypeData.slice(0, showCountWithoutResident)]
                } else {
                    return [...thisTypeData.slice(0, showCountWithoutResident)]
                }
            } else {
                const findItem = thisTypeData[findIndex]
                if (showKeepBg) {
                    return [keepBgItem, findItem, ...thisTypeData.slice(1, showCountWithoutResident)]
                } else {
                    return [findItem, ...thisTypeData.slice(1, showCountWithoutResident)]
                }
            }
        } else {
            if (showKeepBg) {
                return [keepBgItem, ...thisTypeData.slice(0, showCountWithoutResident)]
            } else {
                return [...thisTypeData.slice(0, showCountWithoutResident)]
            }
        }
    }, [scenelDate, keepBgItem, props.sceneName, props.visibleCount, props.backgroundType])

    // 预设参考图，将场景图片加载到canvas上
    useEffect(() => {
        if (props.backgroundType != 1 && props.backgroundType != 0) return
        if (!scenelDate) return
        if (props.sceneName == '00_random' || props.sceneName == '00_keep') return setBgImage()
        
        let foundItem = false
        const thisTypeData = scenelDate
        for (let i = 0; i < thisTypeData.length; i++){
            const item = thisTypeData[i]
            if (item.name == props.sceneName) {
                foundItem = item
                break
            }
            if (item.subList) {
                const item2 = item.subList.find(item => item.name == props.sceneName)
                if (item2) {
                    foundItem = item2
                    break
                }
            }
        }
        if (foundItem) {
            const backgroundType = foundItem.attr.backgroundType
            if (backgroundType == 1) { // 预设参考图
                setBgImage(Utils.addVersionParamToURL(foundItem.attr.refUrl, 'v', foundItem.attr?.version))
            } else if (backgroundType == 0) { // 预设风格
                setBgImage()
            }
        }
        else setBgImage()
    }, [scenelDate, props.sceneName, props.backgroundType])

    return (
        <div className={styles.Container}>
            {/* <div className={styles.Title}>
                <span>{props.backgroundType == 0 ? "预设风格" : props.backgroundType == 1 ? "参考图" : "推荐"}</span>
                <span onClick={e => props.toggleDrawer(e, 'Scene')}>更多&nbsp;&nbsp;<DoubleRightOutlined /></span>
            </div> */}
            <div className={styles.Content}>
            {
                loading ? <div className={styles.empty_wrap}><Spin /></div> :
                showList?.length == 0 ? <div className={styles.empty_wrap}><Empty /></div> : 
                <div className={styles.SelectionItemBox}>
                {
                    showList.map(item => (
                        <SelectionItem
                            key={item.id}
                            showTitle
                            selected={props.sceneName}
                            onClick={selectImgClick}
                            onMoreClick={onMoreSceneChange}
                            record={item}
                        />
                    ))
                }
                </div>
            }
            </div>
        </div>
    )
}